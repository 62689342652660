import React from "react";
import useQueryWaIframeParams from "../../hooks/useQueryWaIframeParams";
import Layout from "../../layouts/LayoutIframe";
import Offer from "../../components/iframe/wa-paywall/Offer";
import "../../components/iframe/wa-paywall/index.scss";

const WaPaywall = () => {
  const { addProps, isRendered, paramsLink } =
    useQueryWaIframeParams("wa-paywall");
  const { software, checkoutLanguage } = addProps;
  const eventCategory = `${software ? software : "LANDING"}|Pricing`;
  const updateLink = (link) => {
    paramsLink && (link += paramsLink);
    return checkoutLanguage ? `/${checkoutLanguage}${link}` : link;
  };

  return isRendered ? (
    <Layout withTagManager={false} className="PgIWP">
      <div className="PgIWP-offers">
        <Offer
          title="Monthly"
          price="$199"
          href={updateLink("/checkout/?bundle=individualreport_monthly")}
          license="month"
          eventCategory={eventCategory}
        />
        <Offer
          title="Annual"
          price="$999"
          href={updateLink("/checkout/?bundle=individualreport_annual")}
          license="year"
          eventCategory={eventCategory}
        />
      </div>
    </Layout>
  ) : null;
};

export default WaPaywall;
